<template>
  <!-- Financing -->
  <div id="story-mode-financing">
    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">
      <loader v-if="!loaded.financing" />

      <!-- Content -->
      <div
        v-if="loaded.financing"
        class="content"
        :class="{ 'position-relative': isFinancesCompleted }"
      >
        <v-app>
          <!-- Page Header -->
          <header class="page-header">
            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ stamp: isFinancesCompleted }"
              :title="$t('steps.setUp.items.financing')"
              :guide-visible="guideVisible"
              :guide-visible-first-time="guideVisibleFirstTime"
              :completed-step-name="'financingCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="openInfo"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
          <!-- /Divider -->
          </header>
          <!-- /Page Header -->
          <!-- Info Guide Expand Panel -->
          <info-guide
            v-if="guideVisibleFirstTime"
            class="mb-5"
            type="financingExamples"
            visible
            @click="guideVisibleFirstTime = !guideVisibleFirstTime"
          />
          <!-- Info Guide Expand Panel -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Charts And Table Placeholders -->
        <div v-if="isFinancesEmpty" class="table-view">
          <el-tabs v-model="activeName">
            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div class="switcher-inner-wrapper">
                <div class="placeholder breakdown" />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div class="switcher-inner-wrapper">
                <div class="placeholder timeline" />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div class="switcher-inner-wrapper">
                <div class="placeholder table" />
              </div>
            </el-tab-pane>
            <!-- /Table -->
          </el-tabs>
        </div>
        <!-- /Charts And Table Placeholders -->

        <!-- Charts And Table Filled -->
        <div
          v-if="!isFinancesEmpty"
          class="table-view filled"
        >
          <el-tabs v-model="activeName">
            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div v-if="activeName === 'first'" class="bg-white">
                <financing-sources
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div v-if="activeName === 'second'" class="bg-white">
                <cash-flow-from-financing-activities
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div v-if="activeName === 'third'" class="switcher-inner-wrapper">
                <financing-table class="pt-4" />
              </div>
            </el-tab-pane>
            <!-- /Table -->
          </el-tabs>
        </div>
        <!-- /Charts And Table Filled -->

        <v-app>
          <!-- Header -->
          <business-guide-header>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />
              <business-guide-search
                v-if="!isFinancesEmpty"
                v-model="searchValue"
                class="mx-1"
              />
              <!-- View Switcher -->
              <view-switcher
                v-if="isFinancesEmpty === false"
                :active-view="$store.state.itemsView.financingView"
                @activate-view="onActivateView"
              />
              <!-- /View Switcher -->
            </template>
          </business-guide-header>
          <!-- /Header -->
        </v-app>

        <!-- List Wrapper -->
        <div
          class="list-wrapper"
          :class="{ active: $store.state.itemsView.financingView === 'list' }"
        >
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isFinancesCompleted"
              @click="checkFinances"
            />
            <!-- /Completed Overlay -->
            <!-- Add Financing -->
            <el-col :md="12" class="d-flex mb-2 position-relative">
              <add-new-card
                class="width-xl-20 py-2 py-md-0"
                :title="$t('addFinancingOption')"
                :disabled="!canEdit || isFinancesCompleted"
                horizontal
                @click="onAddFinance"
              />
            </el-col>
            <!-- /Add Financing -->

            <!-- Placeholder 1 -->
            <el-col v-if="isFinancesEmpty" :md="12">
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 1 -->

            <!-- Placeholder 2 -->
            <el-col v-if="isFinancesEmpty" :md="12">
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 2 -->

            <!-- Placeholder 3 -->
            <el-col v-if="isFinancesEmpty" :md="12">
              <div class="placeholder yellow">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 3 -->

            <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
            <tutorial-tooltip
              v-if="
                $store.state.user.tutorial.financingCompleted === false &&
                  1 === 2
              "
              :title="$t('pages.businessGuide.financing.addFinancingOptions')"
              :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
              :tutorial-name="'financingCompleted'"
            />
            <!-- /Tutorial Tooltip -->

            <!-- List Items -->
            <el-col
              v-for="finance in financesToShowList"
              :key="finance.id"
              class="d-flex mb-2"
              :md="12"
            >
              <div class="d-flex w-100" @click="checkFinances">
                <set-up-and-projections-card
                  class="w-100"
                  :entity="finance"
                  :name="finance.name"
                  :date="getFormattedDate(finance.date)"
                  :amount="getAmount(finance)"
                  :type="finance.type"
                  :disabled="isFinancesCompleted"
                  view="list"
                  @edit="onEditFinance"
                  @copy="onCopyFinance"
                  @delete="onDeleteFinance"
                />
              </div>
            </el-col>
            <!-- /List Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="idea.storyMode.setUp.financing.finances.length > 7">
            <el-col>
              <show-all-toggle
                :show="showAllFinancesList"
                @click="showAllFinancesList = !showAllFinancesList"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /List Wrapper -->

        <!-- Grid Wrapper -->
        <div
          class="grid-wrapper"
          :class="{ active: $store.state.itemsView.financingView === 'grid' }"
        >
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isFinancesCompleted"
              @click="checkFinances"
            />
            <!-- /Completed Overlay -->
            <!-- Add Financing Option -->
            <el-col :md="8" :xl="6" class="d-flex position-relative width-lg-20 mb-4">
              <add-new-card
                class="width-xl-20"
                :title="$t('pages.businessGuide.financing.addFinancingOptions')"
                :disabled="!canEdit || isFinancesCompleted"
                @click="onAddFinance"
              />
            </el-col>
            <!-- /Add Financing Option -->

            <!-- Grid Items -->
            <el-col
              v-for="finance in financesToShowGrid"
              :key="finance.id"
              class="d-flex mb-4 width-lg-20"
              :md="8"
              :xl="6"
            >
              <div class="d-flex w-100" @click="checkFinances">
                <set-up-and-projections-card
                  class="w-100"
                  :entity="finance"
                  :name="finance.name"
                  :date="getFormattedDate(finance.date)"
                  :amount="getAmount(finance)"
                  :type="finance.type"
                  :disabled="isFinancesCompleted"
                  @edit="onEditFinance"
                  @copy="onCopyFinance"
                  @delete="onDeleteFinance"
                />
              </div>
            </el-col>
            <!-- /Grid Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="idea.storyMode.setUp.financing.finances.length > 4">
            <el-col>
              <show-all-toggle
                :show="showAllFinancesGrid"
                @click="showAllFinancesGrid = !showAllFinancesGrid"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /Grid Wrapper -->
      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'setUp'"
        :step="'financing'"
        :concept-name="'financingMoreDetails'"
        :completed-step-name="'financingCompleted'"
      />
      <!-- /Add More Details -->
    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'financingCompleted'"
      :step-filled="idea.storyMode.setUp.financing.finances.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="financingExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="financing"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Financing -->
    <dialog-add-financing
      :dialog-add-financing-visible="dialogAddFinancingVisible"
      :finance-action="financeAction"
      @close-dialog-add-financing="onCloseDialogAddFinancing"
      @close-dialog-on-button="dialogAddFinancingVisible = false"
      @on-update-financing-view="getFinancing"
    />
    <!-- /Dialog Add Financing -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->
  </div>
  <!-- /Financing -->
</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import CashFlowFromFinancingActivities from '@/views/Home/StoryMode/FinancialPlan/Components/Financing/CashFlowFromFinancingActivities'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddFinancing from './Dialogs/DialogAddFinancing'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import FinancingSources from '@/views/Home/StoryMode/FinancialPlan/Components/Financing/FinancingSources'
import FinancingTable from './Tables/FinancingTable'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters, mapState } from 'vuex'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'Financing',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessGuideSearch,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CashFlowFromFinancingActivities,
    CompletedOverlay,
    DashboardHeader,
    DialogAddFinancing,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    FinancingSources,
    FinancingTable,
    Loader,
    MainBottomBar,
    MainNavigation,
    SetUpAndProjectionsCard,
    ShowAllToggle,
    TutorialTooltip,
    ViewSwitcher
  },

  mixins: [
    MixinCurrencies,
    MixinProcessCompleted,
    MixinGuide,
    MixinApiCalculations,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      activeName: 'first',
      dialogAddFinancingVisible: false,
      guideVisible: false,
      financeAction: null,
      showAllFinancesGrid: false,
      showAllFinancesList: false,
      dialogProcessCompletedVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    ...mapGetters('idea', {
      finances: 'getFinances',
      getStepIsCompleted: 'getStepIsCompleted',
      getHasItemsFromTemplate: 'getHasItemsFromTemplate'
    }),

    isFinancesEmpty () {
      return !this.finances.length
    },

    isFinancesCompleted () {
      return this.getStepIsCompleted('financingCompleted')
    },

    financesToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterFinances
      }

      return this.showAllFinancesGrid === true
        ? this.finances
        : this.finances.slice(0, 4)
    },

    financesToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterFinances
      }

      return this.showAllFinancesList === true
        ? this.finances
        : this.finances.slice(0, 7)
    },

    filterFinances () {
      return this.finances.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    ideaStartingFrom () {
      return this.idea.ideaSettings.startingFrom
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getFinances')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.startupCosts'),
        route: 'business-guide-set-up-startup-costs'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.managementTeam'),
        route: 'business-guide-set-up-management-team'
      }
    }
  },

  watch: {
    ideaStartingFrom () {
      this.getFinancing()
    }
  },

  async created () {
    this.openGuide('financingGuide')
    await this.getFinancing()
    await this.getFinancingMoreDetails()
  },

  methods: {
    ...mapActions('idea', [
      'getFinancingMoreDetails',
      'deleteIdeaExamples'
    ]),

    checkFinances () {
      if (!this.canEdit) return
      if (this.isFinancesCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    onAddFinance () {
      if (!this.canEdit) return
      this.dialogAddFinancingVisible = true
    },

    onCloseDialogAddFinancing () {
      this.dialogAddFinancingVisible = false
      this.financeAction = null

      // Workaround to update computed property financesToShowGrid & financesToShowList
      this.updateFinancingView()
    },

    updateFinancingView () {
      this.showAllFinancesList = !this.showAllFinancesList
      this.showAllFinancesList = !this.showAllFinancesList
      this.showAllFinancesGrid = !this.showAllFinancesGrid
      this.showAllFinancesGrid = !this.showAllFinancesGrid
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setFinancingView', view)
    },

    onEditFinance (finance) {
      this.financeAction = Object.assign({}, finance)
      this.dialogAddFinancingVisible = true
    },

    onCopyFinance (finance) {
      this.financeAction = Object.assign({}, finance)
      this.financeAction.id = 0
      this.dialogAddFinancingVisible = true
    },

    onDeleteFinance (finance) {
      this.loaded.financing = false
      this.$store.commit('idea/removeFinance', finance.id)
      this.$http
        .delete(`story-mode/set-up/financing/finances/${finance.id}`)
        .then(response => {
          this.getFinancing()
        })

      // Return to list view, if there are no finances
      if (this.idea.storyMode.setUp.financing.finances.length === 0) {
        this.onActivateView('list')
      }
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onEraseFromTemplate () {
      if (this.isFinancesCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    getAmount (finance) {
      if (finance.type === 'loc') {
        return this.getFormattedAmount(
          finance.howManyTimes * finance.averageWithdrawalAmount
        )
      }

      return this.getFormattedAmount(finance.amount)
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('financing')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.getFinancing()
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
#story-mode-financing {
  margin-left: 0;
}
</style>
